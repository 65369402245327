import { Helmet } from "react-helmet-async";
import React from "react";

const Mentions = () => {
  return (
    <>
      <Helmet>
        <title>Mentions Légales</title>
        <meta
          name="description"
          content="POLITIQUE DE CONFIDENTIALITÉ"
        />
        <link rel="canonical" href="/mentions" />
      </Helmet>
      <section className="w-[300px] 3xl:w-[1500px] 2xl:w-[1200px] xl:w-[1000px] lg:w-[800px] md:w-[650px] xm:w-[500px] sm:w-[320px] mx-auto mt-52 mLegal">
        <div>
          <h1 className="font-bold">POLITIQUE DE CONFIDENTIALITÉ</h1>
          <p>databisolutions.fr</p>
          <h2 className="mt-5">DATA&BI SOLUTIONS</h2>
          <h2>Type de site : vitrine </h2>
          <h3 className="text-left font-bold underline mt-20">
            Le but de cette politique de confidentialité
          </h3>
          <p className="text-left">
            Le but de cette politique de confidentialité est d'informer les
            utilisateurs de notre site des données personnelles que nous
            recueillerons ainsi que les informations suivantes, le cas échéant :
          </p>
          <ul className="text-left ml-16">
            <li> a. Les données personnelles que nous recueillerons </li>
            <li> b. L'utilisation des données recueillies </li>
            <li> c. Qui a accès aux données recueillies</li>
            <li>d. Les droits des utilisateurs du site</li>
            <li> e. La politique de cookies du site</li>
          </ul>
          <p className="text-left">
            Cette politique de confidentialité fonctionne parallèlement aux
            conditions générales d'utilisation de notre site.
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Lois applicables
          </h3>
          <p className="text-left">
            Conformément au Règlement général sur la protection des données
            (RGPD), cette politique de confidentialité est conforme aux
            règlements suivants. Les données à caractère personnel doivent être
            :
          </p>
          <ul className="text-left">
            <li>
              {" "}
              a. traitées de manière licite, loyale et transparente au regard de
              la personne concernée (licéité, loyauté, transparence) ;{" "}
            </li>
            <li>
              {" "}
              b. collectées pour des finalités déterminées, explicites et
              légitimes, et ne pas être traitées ultérieurement d'une manière
              incompatible avec ces finalités ; le traitement ultérieur à des
              fins archivistiques dans l'intérêt public, à des fins de recherche
              scientifique ou historique ou à des fins statistiques n'est pas
              considéré, conformément à l'article 89, paragraphe 1, comme
              incompatible avec les finalités initiales (limitation des
              finalités) ;{" "}
            </li>
            <li>
              {" "}
              c. adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées
              (minimisation des données) ;{" "}
            </li>
            <li>
              d. exactes et, si nécessaire, tenues à jour; toutes les mesures
              raisonnables doivent être prises pour que les données à caractère
              personnel qui sont inexactes, eu égard aux finalités pour
              lesquelles elles sont traitées, soient effacées ou rectifiées sans
              tarder(exactitude) ;{" "}
            </li>
            <li>
              e. conservées sous une forme permettant l'identification des
              personnes concernées pendant une durée n'excédant pas celle
              nécessaire au regard des finalités pour lesquelles elles sont
              traitées: les données à caractère personnel peuvent être
              conservées pour des durées plus longues dans la mesure où elles
              seront traitées exclusivement à des fins archivistiques dans
              l'intérêt public, à des fins de recherche scientifique ou
              historique ou à des fins statistiques conformément à l'article 39,
              paragraphe 1, pour autant que soient mises en œuvre les mesures
              techniques et organisationnelles appropriées requises par le
              règlement afin de garantir les droits et libertés de la personne
              concernée (limitation de la conservation){" "}
            </li>
            <li>
              {" "}
              f. traitées de façon à garantir une sécurité appropriée des
              données à caractère personnel, y compris la protection contre le
              traitement non autorisé ou illicite et contre la perte, la
              destruction ou les dégâts d'origine accidentelle, à l'aide de
              mesures techniques ou organisationnelles appropriées (intégrité et
              confidentialité).{" "}
            </li>
          </ul>
          <p className="text-left">
            Le traitement n'est licite que si, et dans la mesure où, au moins
            une des conditions suivantes est remplie :{" "}
          </p>
          <ul className="text-left">
            <li>
              a. la personne concernée a consenti au traitement de ses données à
              caractère personnel pour une ou plusieurs finalités spécifiques :
            </li>
            <li>
              b. le traitement est nécessaire à l'exécution d'un contrat auquel
              la personne concernée est partie ou à l'exécution de mesures
              précontractuelles prises à la demande de celle-ci{" "}
            </li>
            <li>
              c. le traitement est nécessaire au respect d'une obligation légale
              à laquelle le responsable du traitement est soumis{" "}
            </li>
            <li>
              d. le traitement est nécessaire à la sauvegarde des intérêts
              vitaux de la personne concernée ou d'une autre personne physique ;{" "}
            </li>
            <li>
              e. le traitement est nécessaire à l'exécution d'une mission
              d'intérêt public ou relevant de l'exercice de l'autorité publique
              dont est investi le responsable du traitement ;{" "}
            </li>
            <li>
              f. le traitement est nécessaire aux fins des intérêts légitimes
              poursuivis par le responsable du traitement ou par un tiers, à
              moins que ne prévalent les intérêts ou les libertés et droits
              fondamentaux de la personne concernée qui exigent une protection
              des données à caractère personnel, notamment lorsque la personne
              concernée est un enfant.{" "}
            </li>
          </ul>
          <p className="text-left">
            Pour les résidents de l'État de Californie, cette politique de
            confidentialité vise à se conformer à la California Consumer Privacy
            Act (CCPA). S'il y a des incohérences entre ce document et la CCPA,
            la législation de l'État s'appliquera. Si nous constatons des
            incohérences, nous modifierons notre politique pour nous conformer à
            la loi pertinente.{" "}
          </p>
          <h3 className="text-left font-bold underline mt-10">Consentement</h3>
          <p className="text-left">
            Les utilisateurs conviennent qu'en utilisant notre site, ils
            consentent à :
          </p>
          <ul className="text-left">
            <li>
              {" "}
              a. les conditions énoncées dans la présente politique de
              confidentialité et{" "}
            </li>
            <li>
              {" "}
              b. la collecte, l'utilisation et la conservation des données
              énumérées dans la présente politique.{" "}
            </li>
          </ul>
          <h3 className="text-left font-bold underline mt-20">
            Données personnelles que nous collectons
          </h3>
          <h4 className="text-left font-bold">
            Données collectées automatiquement
          </h4>
          <p className="text-left">
            Lorsque vous visitez et utilisez notre site, nous pouvons
            automatiquement recueillir et conserver les renseignements suivants
            :
          </p>
          <ul className="text-left">
            <li> a. Adresse 1P</li>
            <li> b. Lieu</li>
            <li> c. Détails matériels et logiciels </li>
            <li> d. Liens un utilisateur clique tout en utilisant le site </li>
            <li> e. Contenu que l'utilisateur consulte sur votre site </li>
          </ul>
          <h3 className="text-left font-bold underline mt-10">
            Données recueillies de façon non automatique
          </h3>
          <p className="text-left">
            Nous pouvons également collecter les données suivantes lorsque vous
            effectuez certaines fonctions sur notre site :
          </p>
          <ul className="text-left">
            <li>a. Prénom et nom</li>
            <li>b. Email</li>
          </ul>
          <p className="text-left">
            Ces données peuvent être recueillies au moyen des méthodes suivantes
            : Veuillez noter que nous ne collectons que les données qui nous
            aident à atteindre l'objectif énoncé dans cette politique de
            confidentialité. Nous ne recueillerons pas de données
            supplémentaires sans vous en informer au préalable. Comment nous
            utilisons les données personnelles Les données personnelles
            recueillies sur notre site seront utilisées uniquement aux fins
            précisées dans la présente politique ou indiquées sur les pages
            pertinentes de notre site. Nous n’utiliserons pas vos données
            au-delà de ce que nous divulguerons.
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Avec qui nous partageons les données personnelles
          </h3>
          <h4 className="text-left font-bold">Employés</h4>
          <p className="text-left">
            Nous pouvons divulguer à tout membre de notre organisation les
            données utilisateur dont il a raisonnablement besoin pour réaliser
            les objectifs énoncés dans la présente politique.
          </p>
          <h4 className="text-left font-bold">Tierces parties</h4>
          <p className="text-left">
            Nous ne partageons pas les données utilisateur avec les tien
            suivants : Nous pouvons partager les données utilisateur avec des
            tiers aux fins suivantes : Les tiers ne seront pas en mesure
            d'accéder aux données des utilisateurs au-delà de ce qui est
            raisonnablement nécessaire pour atteindre Les tiers ne seront pas en
            mesure d'accéder aux données des utilisateurs au-delà de ce qui est
            raisonnablement nécessaire pour atteindre l'objectif donné.
          </p>
          <h4 className="text-left font-bold">Autres divulgations</h4>
          <p className="text-left">
            Nous nous engageons à ne pas vendre ou partager vos données avec des
            tiers, sauf dans les cas suivants :
          </p>
          <ul className="text-left">
            <li>a. si la loi l'exige</li>
            <li>b. si elle est requise pour toute procédure judiciaire</li>
            <li>c. pour prouver ou protéger nos droits légaux</li>
            <li>
              d. à des acheteurs ou des acheteurs potentiels de cette société
              dans le cas où nous cherchons à la vendre la société.
            </li>
            Si vous suivez des hyperliens de notre site vers mi autre site,
            veuillez noter que nous ne sommes pas responsables et n'avons pas de
            contrôle sur leurs politiques et pratiques de confidentialité.
          </ul>
          <h3 className="text-left font-bold underline mt-10">
            Combien de temps nous stockons les données personnelles
          </h3>
          <p className="text-left">
            Nous ne conservons pas les données des utilisateurs au-delà de ce
            qui est nécessaire pour atteindre les fins pour lesquelles elles
            sont recueillies.
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Comment nous protégeons vos données personnelles
          </h3>
          <p className="text-left">
            Alors que nous prenons toutes tes précautions raisonnables pour nous
            assurer que nos données d'utilisateur sont sécurisées et que les
            utilisateurs sont protégés, il reste toujours du risque de
            préjudice. L'Internet en sa totalité peut être, parfois, peu sûr et
            donc nous sommes incapables de garantir la sécurité des données des
            utilisateurs au-delà de ce qui est raisonnablement pratique.
          </p>
          <h3 className="text-left font-bold underline mt-10">Mineurs</h3>
          <p className="text-left">
            Le RGPD précise que les personnes de moins de 15 ans sont
            considérées comme des mineurs aux fins de la collecte de données.
            Les mineurs doivent avoir le consentement d'un représentant légal
            pour que leurs données soient recueillies, traitées et utilisées.
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Vos droits en tant qu'utilisateur
          </h3>
          <p className="text-left">
            En venu du RGPD, les utilisateurs ont les droits suivants en tant
            que personnes concernées :
          </p>
          <ul className="text-left">
            <li>a. droit d'accès</li>
            <li>b. droit de rectification</li>
            c. droit à l'effacement
            <li>d. droit de restreindre le traitement</li>
            <li>e. droit à la portabilité des données</li>
            <li>f. droit d'objection</li>
          </ul>
          <p className="text-left">
            Vous trouverez de plus amples informations sur ces droits au
            chapitre 3 (art 12-23) du RGPD.
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Comment modifier, supprimer ou contester les données recueillies
          </h3>
          <p className="text-left">
            Si vous souhaitez que vos renseignements soient supprimés ou
            modifiés d'une façon ou d'une autre veuillez communiquer avec notre
            agent de protection de la vie privée ici : info@databisolutions.fr
          </p>
          <h3 className="text-left font-bold underline mt-10">
            Politique sur les cookies
          </h3>
          <p className="text-left">
            Un cookie est un petit fichier, stocké sur le disque dur d'un
            utilisateur par le site Web. Son but est de recueillir des données
            relatives aux habitudes de navigation de l'utilisateur. Nous
            utilisons les types de cookies suivants sur notre site :
          </p>
          <ul className="text-left">
            <li>a. Cookies fonctionnels</li>
          </ul>
          <p className="text-left">
            Nous les utilisons pour mémoriser toutes les sélections que vous
            faites sur notre site afin qu'elles soient sauvegardées pour vos
            prochaines visites. Vous pouvez choisir d'être averti chaque fois
            qu'un cookie est transmis. Vous pouvez également choisir de
            désactiver les cookies entièrement dans votre navigateur Internet,
            mais cela peut diminuer la qualité de votre expérience
            d'utilisation.
          </p>
          <h3 className="text-left font-bold underline mt-10">Modifications</h3>
          <p className="text-left">
            Cette politique de confidentialité peut être modifiée à l'occasion
            afin de maintenir la conformité avec la loi et de tenir compte de
            tout changement à notre processus de collecte de données. Nous
            recommandons à nos utilisateurs de vérifier notre politique de temps
            à autre pour s'assurer qu'ils soient informés de toute mise à jour.
            Au besoin, nous pouvons informer les utilisateurs par courriel des
            changements apportés à cette politique.
          </p>
          <h3 className="text-left font-bold underline mt-10">Contact</h3>
          <p className="text-left mb-20">
            Si vous avez des questions à nous poser, n'hésitez pas à communiquer
            avec nous en utilisant ce qui suit : info@databisolutions.fr
          </p>
        </div>
      </section>
    </>
  );
};

export default Mentions;
